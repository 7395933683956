import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ian/source/repos/oconnorit/src/components/projectTemplate.tsx";
import moveeOptions from "../../assets/videos/movee_options.mp4";
import moveeExample from "../../assets/videos/movee_example.mp4";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Movee is an app which makes it easier for a group of people to select a movie or
show to stream that that all mutually like. The user has the ability to choose
from multiple streaming services to receive movies and shows from. The user can
also choose whether they want to watch only movies, only shows, or both. Once a
session has started, all users then receive a movie/show, and they can decide
whether or not they like it. After they have all chosen a set number of
movies/shows, they receive their mutually liked movies/shows.`}</p>
    <h2>{`Example Usage`}</h2>
    <video controls className="image fit">
  <source src={moveeExample} type="video/mp4" />
    </video>
    <h2>{`Streaming Service Options`}</h2>
    <div style={{
      "width": "20%"
    }}>
  <video controls loop className="image fit">
    <source src={moveeOptions} type="video/mp4" />
  </video>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      