import React from "react";
import Helmet from "react-helmet";
import Layout from "./layout";
import { Link } from "gatsby";

import LanguageIcons from "./LanguageIcons";

const BackButton = () => {
  return (
    <Link to="/" className={"button back"}>
      {"Back"}
    </Link>
  );
};
const ProjectHeader = ({
  title,
  languages,
}: {
  title: string;
  languages: string[];
}) => {
  return (
    <div className="projectHeader">
      <h1>{title}</h1>
      <LanguageIcons languages={languages} />
    </div>
  );
};

const ProjectPage: React.FC = ({ children, pageContext }) => {
  const { title, languages } = pageContext.frontmatter;
  const projectDescription = "projectDescription";
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={projectDescription} />
      </Helmet>
      <div id="main">
        <section id="project">
          <BackButton />
          <hr />
          <ProjectHeader title={title} languages={languages} />
          {children}
        </section>
      </div>
    </Layout>
  );
};

export default ProjectPage;
