import React from "react";
interface Props {
  languages: string[];
}

//TODO: Need to actually style the color with scss
const LanguageIcons: React.FC<Props> = ({ languages }) => {
  return (
    <div className="languageTags">
      {languages.map((languageIdentifier, idx) => {
        const language = languageIdentifier;
        const colorVar = `$github-color-${languageIdentifier.toLowerCase()}`; //Not going to work
        return <span key={idx}>{language}</span>;
      })}
    </div>
  );
};

export default LanguageIcons;
